import { Controller } from "@hotwired/stimulus";

// Search used: controller: "toggle"
// Search used: data-controller="toggle"
export default class extends Controller {
  static values = { tid: String };
  toggle(e) {
    e.preventDefault();
    let target = document.getElementById(this.tidValue);

    for (const cssName of this.cssClass) {
      if(target.classList.contains(cssName)) {
        target.classList.remove(cssName);
        document.getElementById("menu_handle1").classList.add("hidden");
        document.getElementById("menu_handle2").classList.remove("hidden");
      } else {
        target.classList.add(cssName);
        document.getElementById("menu_handle2").classList.add("hidden");
        document.getElementById("menu_handle1").classList.remove("hidden");
      }
      
    }

    let inputs = target.getElementsByTagName("input");
    for (let i = 0; i < inputs.length; i++) {
      let input_e = inputs[i];
      if (input_e.getAttribute("type") == "text") {
        input_e.focus();
        break;
      }
    }
  }

  // click@window->toggle#hide
  hide(event) {
    let target = document.getElementById(this.tidValue);
    if (
      event &&
      (this.element.contains(event.target) ||
        this.element.contains(event.target))
    ) {
      // event.preventDefault(); // I don't remeber why I did it, but i need this line to be commented
      return;
    }

    let f = target.offsetLeft;
    var style = window.getComputedStyle(target);
    if (style.visibility === "hidden") {
      return;
    }

    for (const cssName of this.cssClass) {
      target.classList.toggle(cssName, true);
    }
    document.getElementById("menu_handle1").classList.remove("hidden");
    document.getElementById("menu_handle2").classList.add("hidden");
  }

  // Usage eg: data-toggle-css-class="invisible popshow"
  get cssClass() {
    let names = this.data.get("cssClass").trim().split(/\s+/);
    return names;
  }
}
