import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    console.log("alert controller ");
    // this.element.classList.add('transform', 'opacity-0', 'transition', 'duration-1000');
    setTimeout(() => {
      // this.element.remove();
      this.element.classList.add("animate__fadeOut");
      setTimeout(() => {
        this.element.remove();
      }, 500);
    }, 2500);
  }
}
