import { Controller } from "@hotwired/stimulus";

import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

export default class extends Controller {
  connect() {
    this.swiper = new Swiper(this.element, {
      modules: [Navigation, Pagination, Autoplay],
      cssMode: true,
      centeredSlides: true,
      loop: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      autoplay: {
        delay: 4000,
        disableOnInteraction: true,
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true
      },
      mousewheel: true,
      keyboard: true,
    });

  }

  disconnect() {
    this.swiper.destroy()
    this.swiper = undefined;
  }
}
