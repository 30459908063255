import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "checkbox", "count" ]
  connect() {
    this._display_selected_count();
  }

  toggle_select(e) {    
    this.checkboxTargets.forEach(checkbox_element => checkbox_element.checked = e.target.checked);
    this._display_selected_count();
  }

  single_select(e){
    this._display_selected_count();
  }

  _display_selected_count(){
    let selected_count = 0;
    let selected_ids = [];
    this.checkboxTargets.forEach(checkbox_element => {
      if (checkbox_element.checked === true) { 
        selected_count = selected_count + 1
        selected_ids.push(checkbox_element.value);
      }
    });        
    this.countTarget.innerText = `${selected_count}`
    const ids = this.element.querySelectorAll("[name='selected_ids']");
    ids.forEach(ids_element => {
      ids_element.value = selected_ids.join(","); 
    });
    if(selected_count > 0) {
      this.element.querySelectorAll(".tool-btn").forEach(btn => {
        btn.classList.add("bg-red-200");
      });
    } else {
      this.element.querySelectorAll(".tool-btn").forEach(btn => {
        btn.classList.remove("bg-red-200");
      });
    }
  }
}
