import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { offset: Number };

  initialize() {
    this.detect_scroll = this.detect_scroll.bind(this);
  }

  connect() {
    window.addEventListener("scroll", this.detect_scroll, { passive: true });
  }

  detect_scroll() {
    if (window.scrollY > this.offsetValue) {
      for (const cssName of this.cssClass) {
        this.element.classList.add(cssName);
      }
    } else {
      for (const cssName of this.cssClass) {
        this.element.classList.remove(cssName);
      }
    }
  }

  disconnect() {
    window.removeEventListener("scroll", this.detect_scroll);
  }

  // Usage eg: data-sticky-css-class="fixed"
  get cssClass() {
    let names = this.data.get("cssClass").trim().split(/\s+/);
    return names;
  }
}
