// Entry point for the build script in your package.json
import "@hotwired/turbo-rails";
import "./controllers";
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';

import smoothscroll from 'smoothscroll-polyfill';
 
// kick off the polyfill!
smoothscroll.polyfill();

document.addEventListener("DOMContentLoaded", () => {
  // const swiper = new Swiper(".mySwiper", {
  //   modules: [Navigation, Pagination, Autoplay],
  //   cssMode: true,
  //   centeredSlides: true,
  //   loop: true,
  //   navigation: {
  //     nextEl: ".swiper-button-next",
  //     prevEl: ".swiper-button-prev",
  //   },
  //   autoplay: {
  //     delay: 3500,
  //     disableOnInteraction: true,
  //   },
  //   pagination: {
  //     el: ".swiper-pagination",
  //     clickable: true
  //   },
  //   mousewheel: true,
  //   keyboard: true,
  // });
});

