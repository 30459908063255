import { Controller } from "@hotwired/stimulus";

// Search usage: data-controller="dropdown"
export default class extends Controller {
  static targets = ["menu", "btn"];

  connect() {
    let rect = this.menuTarget.getBoundingClientRect();
    let size_w = rect.x + rect.width;
    if (size_w >= window.innerWidth) {
      let new_x = window.innerWidth - rect.width - 32;
      this.menuTarget.style.left = `${new_x}px`;
    }
  }

  popshow(e) {
    clearTimeout(this.timeout);
    e.preventDefault();
    this.menuTarget.classList.remove("invisible");
    this.menuTarget.classList.add("popshow");
  }

  will_hide(e) {
    let event_type = e.type;
    if (event_type === "click") {
      if (this.element.contains(e.target) || this.element === e.target) {
        return;
      }
    }

    if (this.menuTarget.classList.contains("popshow")) {
      let timeout_delay = event_type === "click" ? 0 : 300;
      clearTimeout(this.timeout);
      e.preventDefault();
      this.timeout = setTimeout(() => {
        this.menuTarget.classList.remove("popshow");
        this.menuTarget.classList.add("invisible");
      }, timeout_delay);
    }
  }

  view_menus(e) {
    e.preventDefault();
    clearTimeout(this.timeout);
  }

  toggle(e) {
    e.preventDefault();
    if (this.menuTarget.classList.contains("hidden")) {
      this.menuTarget.classList.remove("hidden");
      this.menuTarget.classList.add("block");
    } else {
      this.menuTarget.classList.remove("block");
      this.menuTarget.classList.add("hidden");
    }
  }
}
