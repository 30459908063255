import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "side1", "side2" ]

  connect() {
    console.log("modelview controller ");
    this.side2Target.style.display = "none";
    
  }

  switch_side1() {
    console.log("modelview controller switch");
    this.side2Target.style.display = "none";
    this.side1Target.style.display = "block";
  }

  switch_side2() {
    console.log("modelview controller switch");
    this.side1Target.style.display = "none";
    this.side2Target.style.display = "block";
  }
}
