import { Controller } from "@hotwired/stimulus";

import * as ActiveStorage from "@rails/activestorage";
import { DirectUpload } from "@rails/activestorage";

ActiveStorage.start();

export default class extends Controller {
  static targets = ["avatarFileField", "avatarPreview"];

  show(event) {
    event.stopPropagation();
    if (this.element.hasAttribute("open")) {
      this.element.removeAttribute("open");
    } else {
      this.element.setAttribute("open", "");
    }
  }

  select_photo(event) {
    event.preventDefault();
    this.avatarFileFieldTarget.click();
  }

  direct_upload() {
    console.log(this.avatarFileFieldTarget.files);
    let url = this.avatarFileFieldTarget.dataset.directUploadUrl;
    const token = this.avatarFileFieldTarget.dataset.directUploadToken;
    const attachmentName =
      this.avatarFileFieldTarget.dataset.directUploadAttachmentName;
    let file_item = this.avatarFileFieldTarget.files[0];
    let upload = new DirectUpload(file_item, url, token, attachmentName);
    upload.create((error, blob) => {
      if (error) {
        // Handle the error
        console.log("upload error.....", error);
      } else {
        // Add an appropriately-named hidden input to the form with a
        //  value of blob.signed_id so that the blob ids will be
        //  transmitted in the normal upload flow
        this.avatarFileFieldTarget.value = null;
        const hiddenField = document.createElement("input");
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("value", blob.signed_id);
        hiddenField.name = this.avatarFileFieldTarget.name;
        this.element.querySelector("form").appendChild(hiddenField);

        this.avatarPreviewTarget.setAttribute(
          "src",
          "http://127.0.0.1:3000/rails/active_storage/blobs/" +
            blob.signed_id +
            "/" +
            blob.filename
        );
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("value", blob.signed_id);
        hiddenField.name = this.avatarFileFieldTarget.name;
        this.element.querySelector("form").appendChild(hiddenField);
        setTimeout(() => {
          this.element.querySelector("form").submit();
        }, 1);
      }
    });
  }
}
